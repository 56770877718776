.leasing-color-red {
    * {
        color: red !important;
    }
}
.leasing-color-green {
    * {
        color: green !important;
    }
}
